/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

const configs =  {
  // eslint-disable-next-line
  NETWORK: {
    NAME: "Sepolia Testnet",
    SYMBOL: "ETH",
    ID: 11155111,
    RPC_URI: "https://rpc.sepolia.dev",
    BLOCK_EXPLORE_URL: "https://sepolia.etherscan.io/",
  },
  SITE_URL: "https://test-presale.coin7.org/",
  COIN7_CONTRACT_ADDRESS: "0x92b99e1e003B661E3ba8c9E85F1a4A53BF57Ffa8",
  CROWDSALE_CONTRACT_ADDRESS: "0x7c834f4920E8753cdC94652158c775004839bC77",
  PRESALE_CONTRACT_ADDRESS: "0x5516a6e0cC74077061c86B2AEe46DcbE96582333",
  USDT_CONTRACT_ADDRESS: "0x6FF983c9c83435C64CE8CFFca82aA356e29A5012",
  PRICE_ORACLE: "0x9EE9d71B475b8b3900eA80e87cD25751C8599164",
  USDT_DECIMAL: 18
}

export default configs